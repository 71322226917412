.profile-banner {
  position: relative;
  min-height: 250px;
}
.profile-banner .banner-image {
  min-height: 250px;
}
.profile-picture {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 196px;
  width: 261px;
}
.ant-upload-list-item-name {
  color: white !important;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: white !important;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: white !important;
}
.ant-upload.ant-upload-select {
  display: inline-block;
  position: relative;
}
.user-management-form .ant-select-selection-item {
  color: black !important;
}
