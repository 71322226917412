.profile-banner {
  position: relative;
}
.lockbanner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lockbanner img {
  max-width: 150px;
  height: 150px !important;
}
