.rdrDateRangePickerWrapper {
  position: absolute;
  left: 50%;
  z-index: 1;
}
.buttonDate:hover {
  background-color: white !important;
  color: black !important;
}
.buttonDate {
  color: red;
}
.search-report {
  display: flex;
  gap: 20px;
}
@media (max-width: 768.98px) {
  .ant-form {
    display: block !important;
  }
  .custom-date {
    margin-left: 10% !important;
  }
}
