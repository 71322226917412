.ctable-striped tr:nth-child(2n) td {
  background-color: #f1f1f1 !important;
}

.ctable-striped th {
  background-color: #c9c9c9 !important;
}
.ant-table-pagination.ant-pagination {
  margin-right: 1rem !important;
}
.rdrDateRangePickerWrapper {
  position: absolute;
  top: 30%;
  /* left: 50%; */
  z-index: 100!important;
}
