.custom-date button {
  background-color: white;
  outline: none;
  color: #ccced1;
}
.custom-date button:focus {
  background-color: none;
  outline: none;
  color: #ccced1;
}
.custom-date p {
  color: #ccced1;
}

//////////////////////////////////
/// Form search

.formsearch-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  &__report-type {
    display: flex;
    gap: 20px;
  }
  &__unit {
    display: flex;
    gap: 20px;
  }
  &__date {
    display: flex;
    gap: 20px;
  }
  &__search {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 1024.98px) {
  .formsearch-wrapper {
    justify-content: left;
    margin-left: auto;

    &__report-type {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }

    &__unit {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
    &__date {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
    &__search {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
  }
}
@media (max-width: 768.98px) {
  .formsearch-wrapper {
    &__date {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
      justify-content: end;
    }
  }
}
