.rdrDateRangePickerWrapper {
  position: absolute;
  /* left: 50%; */
  z-index: 1;
}
.buttonDate:hover {
  background-color: white !important;
}

.custom-date-label button {
  background-color: white;
  outline: none;
  color: #ccced1;
}
.custom-date-label button:focus {
  background-color: none;
  outline: none;
  color: #ccced1;
}
.custom-date-label p {
  color: #ccced1;
}
.container .info-box-wrapper {
  position: relative;
}
.container .info-box {
  user-select: none;
  width: 300px;
  background: #ffc00d;
  font-size: 14px;
  padding: 12px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  top: 20px;
  position: absolute;
}
//////////////////////////////////
/// Form search
.gap {
  display: flex;
  gap: 10px;
}

.formsearch-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  &__report-type {
    display: flex;
    gap: 20px;
  }
  &__unit {
    display: flex;
    flex-grow: 1;
    gap: 20px;
  }
  &__date {
    display: flex;
    gap: 20px;
  }
  &__date2 {
    display: flex;
    gap: 20px;
  }
  &__search {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 1024.98px) {
  .formsearch-wrapper {
    justify-content: space-between;
    margin-left: auto;

    &__report-type {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }

    &__unit {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
    &__date,
    &__date2 {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
    &__search {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
  }
}
@media (max-width: 768.98px) {
  .formsearch-wrapper {
    &__date,
    &__date2 {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
      justify-content: end;
    }
  }
  .custom-date-label {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
}
