.add-edit-record {
  .table-wrapper {
    border-radius: 0.5rem;
    border: solid 1px #c5c5c5;

    .table-list {
      width: 100%;

      tr {
        border-bottom: solid 1px #c5c5c5;

        th {
          padding-left: 10px;
          border-right: solid 1px #c5c5c5;
        }

        td {
          input {
            width: 100%;
            height: 2rem;
            padding-left: 10px;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .ant-select-selection-item {
    font-weight: bold;
  }
}
