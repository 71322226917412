.rdrDateRangePickerWrapper {
  position: absolute;
  left: 50%;
  z-index: 1;
}
.buttonDate:hover {
  background-color: white !important;
  color: black !important;
}
.buttonDate {
  color: red;
} //////////////////////////////////
/// Form search

.formsearch-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  &__filter-1 {
    display: flex;
    gap: 20px;
    .ant-form-item-row {
      text-align: center;
    }
  }
  &__filter-2 {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 1024.98px) {
  .formsearch-wrapper {
    justify-content: left;
    margin-left: auto;
  }
}
@media (max-width: 768.98px) {
  .ant-form {
    display: block !important;
  }
  .custom-date {
    margin-left: 10% !important;
  }
}
