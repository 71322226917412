.ck.ck-editor {
  position: relative;
  height: 500px;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  height: 500px;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important; */
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background-color: white;
  min-height: 500px;
  border: none !important;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: var(--ck-focus-ring);
  box-shadow: var(--ck-inner-shadow), 0 0;
  outline: none;
  min-height: 500px;
}
