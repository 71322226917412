@import url(~antd/dist/antd.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --red-10: #d94647;
  --red-20: #cd1f30;
  --red-30: #d63d4b;
  --red-40: #ff4758;
  --blue-10: #337eac;
  --blue-20: #144361;
  --gray-10: #cccccc;
  --gray-20: #7e7c7c;
  --gray-30: #e3e3e3;
  --gray-40: #4e4e4e;
  --gray-50: #b9b9b9;
  --gray-60: #b1b1b1;
  --gray-70: #707070;
  --gray-80: #f0f0f1;
  --orange-10: #ddaf32;
  --green-10: #42ba96;
  --gray-90: #f8f8f8;
  --black-10: #101010;
  font-family: 'Nunito Sans', sans-serif;
}

body {
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utilities  */
/* Text Colors */
.text-red-10 {
  color: var(--red-10);
}

.text-orange-10 {
  color: var(--orange-10);
}

.text-yellow-10 {
  color: var(--yellow-10);
}

.text-purple-10 {
  color: var(--purple-10);
}

.text-brown-10 {
  color: var(--brown-10);
}

.text-green-10 {
  color: var(--green-10);
}

.text-red-20 {
  color: var(--red-20);
}

.text-red-30 {
  color: var(--red-30);
}

.text-red-40 {
  color: var(--red-40);
}

.text-blue-10 {
  color: var(--blue-10);
}

.text-blue-20 {
  color: var(--blue-20);
}

.text-gray-10 {
  color: var(--gray-10);
}

.text-gray-20 {
  color: var(--gray-20);
}

.text-gray-30 {
  color: var(--gray-30);
}

.text-gray-90 {
  color: var(--gray-90);
}

.text-gray-40 {
  color: var(--gray-40);
}

.text-gray-50 {
  color: var(--gray-50);
}

.text-gray-60 {
  color: var(--gray-60);
}

.text-black-10 {
  color: var(--black-10);
}

/* Background Colors */
.bg-yellow-10 {
  background: var(--yellow-10);
}

.bg-orange-10 {
  background: var(--orange-10);
}

.bg-green-10 {
  background: var(--green-10);
}

.bg-brown-10 {
  background: var(--brown-10);
}

.bg-purple-10 {
  background: var(--purple-10);
}

.bg-green-40 {
  background: var(--green-40);
}

.bg-gray-80 {
  background: var(--gray-80);
}

.bg-red-10 {
  background: var(--red-10);
}

.bg-red-20 {
  background: var(--red-20);
}

.bg-blue-10 {
  background: var(--blue-10);
}

.bg-blue-20 {
  background: var(--blue-20);
}

.bg-black-10 {
  background: var(--black-10);
}

.bg-gray-10 {
  background: var(--gray-80);
}

.bg-gray-90 {
  background: var(--gray-90);
}

/* Borders */
.border-h-gray {
  border-top: 1px solid #707070;
}

.border-gray {
  border-color: #c5c5c5;
}

.border-gray-90 {
  border-color: var(--gray-90);
}

.border-gray-20 {
  border-color: var(--gray-70);
}

.border-gray-30 {
  border-color: var(--gray-80);
}

.opac-bg {
  background-color: rgba(0, 0, 0, 0.3);
}

.card-rounded {
  border-radius: 20px;
}

.rounded-top {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.text-control-1 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-control-2 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-control-3 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hasErrorField {
  border-color: #e42f30 !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 0;
}

.ant-btn {
  font-size: 0.8rem;
  font-weight: bold;
  border: none;
  border-radius: 0.2rem;
}

.ant-btn:hover {
  background: #337eac;
  color: #fff;
}

.ant-btn:focus {
  background: #337eac;
  color: #fff;
}

.ant-upload {
  border-radius: 10rem;
  outline: none;
}

.bg-auth-landing {
  background: url('./shared/images/background.png');
  opacity: 75% !important;
}

.bg-unsplash {
  background: url('./shared/images/dashboardLanding.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-group {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -3rem;
}

/* .main-container{
  width: 100%;
  height: 100vh;
  margin-left: auto;
} */

/* .sidebar{
  height: 100vh;
} */

@media screen and (max-width: 1280px) {
  .sidebar {
    display: none;
  }

  .sidebar-btn-lg-screen {
    display: none;
  }
}

@media screen and (min-width: 1281px) {
  .sidebar-btn-sm-screen {
    display: none;
  }
}

.sub-container {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  background: #fff;
  border-radius: 1.5rem;
  overflow: hidden;
}

.profile-banner {
  /* width     : 110%; */
  height: 16rem;
  object-fit: cover;
  /* margin-top: -2.5rem; */
}

.announcement-banner {
  width: 106.2%;
  height: 13rem;
  object-fit: cover;
  background: black;
  margin: -2.5rem 0 2rem -2.5rem;
}

.file-uploader {
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4rem;
}
