@font-face {
  font-family: CooperBlack;
  src: url(../../../shared/assets/fonts/Cooper\ Black\ Regular.ttf);
}

.login_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}
.modal.fade .modal-dialog {
  max-width: 700px;
  margin: 1.75rem auto;
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-bottom: 2rem;
}

.home__logo {
  width: 200px;
  min-height: 200px;
  margin-bottom: 2rem;
  background: url('../../../shared/assets/pnpedpcr_logo.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.home__brand {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  width: 100%;
  /* padding: 1rem 4rem;
  border-radius: 4rem;
  background-color: rgba(255, 255, 255, 0.6); */
}
.home_message {
  position: absolute;
 
  color: white;
}

.header__text {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: CooperBlack;
  letter-spacing: 8px;
}

.header__text::first-letter {
  color: green;
}

.login__form {
  width: 100%;
  margin-top: 10%;
}

@media screen and (min-width: 992px) {
  .home {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .home__logo {
    position: absolute;
    top: -100px;
    left: 0;
    z-index: -10;
    width: 420px;
    height: 420px;
  }

  .home__brand {
  }

  .home__brand:first-child {
    margin-bottom: 5rem;
    /* background-color: red; */
  }

  .header__text {
    font-size: 2.5rem;
  }

  .login__form {
    width: 80%;
  }
}

@media screen and (min-width: 1200px) {
  .home__logo {
    top: -120px;
    left: 0;
  }

  .header__text {
    font-size: 3rem;
  }
}
