.dashboardform {
  display: flex;

  justify-content: space-between;

  &__reportprofile {
    display: flex;
    flex: 1;
  }
  &__cardprofile {
    display: flex;
    flex: 0 0 30%;
  }
}
.dashboardform__reportprofile {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  .reportprofile__announcement {
    flex: 0 1 50%;
  }
  .reportprofile__total-pending-reports {
  }
  .reportprofile__total-recent-report-activity {
  }
}
