//////////////////////////////////
/// Form search

.formsearch-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2%;
  &__search {
    display: flex;
    gap: 20px;
  }
  &__filter {
    display: flex;
    gap: 20px;
    flex-grow: 1;
  }

  .filter {
    &__date-user {
      display: flex;
      gap: 20px;

      .ant-form-item-row {
        text-align: center;
      }
    }
    &__add-account {
      display: flex;

      gap: 20px;
    }
    &__unit {
      display: flex;

      gap: 20px;
    }
  }
}

@media (max-width: 1024.98px) {
  .formsearch-wrapper {
    justify-content: left;
    margin-left: auto;

    &__report-type {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }

    &__unit {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
    &__date-user {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
    &__search {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
    }
  }
}
@media (max-width: 768.98px) {
  .formsearch-wrapper {
    &__date {
      display: flex;
      gap: 20px;
      flex: 0 0 50%;
      justify-content: end;
    }
  }
}
